<template>
  <YMap class="h-100" :settings="settings" :coords="value" @click="onMapClicked" zoom="15">
    <YMarker marker-type="Placemark" marker-id="place" :coords="value" />
  </YMap>
</template>

<script>
import axios from 'axios';
import { yandexMap as YMap, ymapMarker as YMarker } from 'vue-yandex-maps';

export default {
  name: 'YandexMap',
  components: { YMap, YMarker },
  props: { value: { type: Array, required: true } },
  data() {
    return {
      settings: {
        apiKey: '9fe5aca9-99e6-48c7-8c70-8f43ec71759c',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1',
      },
    };
  },
  methods: {
    async onMapClicked(event) {
      const coords = event.get('coords');
      this.$emit('input', coords);
      this.composeEmit(coords);
    },
    findByCoords([lat, long] = [0, 0]) {
      const params = {
        geocode: `${long},${lat}`,
      };
      return axios
        .get('https://geocode-maps.yandex.ru/1.x/', {
          params: {
            apikey: '9fe5aca9-99e6-48c7-8c70-8f43ec71759c',
            format: 'json',
            ...params,
          },
          headers: {
            ContentType: 'application/json',
            Accept: 'application/json',
          },
        })
        .then(({ data }) => {
          return data.response.GeoObjectCollection.featureMember;
        });
    },
    async composeEmit(coords) {
      const places = await this.findByCoords(coords);

      this.$emit('change', {
        coords,
        best: places?.[0]?.GeoObject,
        places,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>

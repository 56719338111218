<template>
  <div class="mt-2 d-flex flex-row flex-wrap" style="gap: 0.5rem">
    <Photo
      v-for="(photo, index) in list"
      :photo="photo"
      :key="index"
      @delete="$emit('delete', $event)"
    />
    <DropBox
      multiple
      accepted-only
      accept=".jpg,.jpeg,.bmp,.png,.webp"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import Photo from '@/components/galery/Photo.vue';
import DropBox from '@/components/DropBox.vue';

export default {
  name: 'Gallery',
  components: { Photo, DropBox },
  props: { list: { type: Array, required: true } },
};
</script>

<style lang="scss" scoped></style>

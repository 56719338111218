<template>
  <div>
    <div class="d-flex justify-content-between">
      <h4>{{ pageTitle }}</h4>
      <div>
        <b-button
          v-if="componentMode === 'create'"
          type="submit"
          form="placeForm"
          class="mr-2"
          variant="outline-primary"
        >
          Создать
        </b-button>
        <b-button
          v-if="componentMode === 'edit'"
          type="submit"
          form="placeForm"
          class="mr-2"
          variant="outline-primary"
        >
          Сохранить
        </b-button>
        <b-button variant="outline-secondary" @click="cancel">Отмена</b-button>
      </div>
    </div>
    <b-row class="mt-2">
      <b-col cols="6">
        <form @submit.prevent="onSubmit" id="placeForm">
          <b-form-group label="Название" label-class="pb-0">
            <b-form-input required v-model="model.title" autofocus />
          </b-form-group>
          <b-form-group label="Тип" label-class="pb-0">
            <b-form-select required v-model="model.place_type_id">
              <b-form-select-option v-for="type in placeTypes" :key="type.id" :value="type.id">
                {{ type.title }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group label="Описание" label-class="pb-0">
            <b-form-textarea v-model="model.description" rows="5" />
          </b-form-group>

          <b-form-group label="Адрес" label-class="pb-0">
            <b-form-input required v-model="model.address" />
          </b-form-group>

          <b-form-group label="Широта" label-class="pb-0">
            <b-form-input required disabled v-model="model.lat" />
          </b-form-group>
          <b-form-group label="Долгота" label-class="pb-0">
            <b-form-input required disabled v-model="model.lon" />
          </b-form-group>

          <b-form-group label="Ссылка" label-class="pb-0">
            <b-form-input type="url" v-model="model.link_url" />
          </b-form-group>

          <b-form-group
            v-if="model.link_url"
            label="Показать текст вместо ссылки (необязательно)"
            label-class="pb-0"
          >
            <b-form-input v-model="model.link_title" />
          </b-form-group>

          <b-form-checkbox v-model="model.published" class="mt-3">Опубилковано</b-form-checkbox>
          <b-form-checkbox v-model="model.show_on_homepage" class="mt-3">
            Показать на главной
          </b-form-checkbox>
          <button class="d-none" type="submit"></button>
        </form>
      </b-col>
      <b-col cols="6">
        <YandexMap v-model="coords" @change="onPlaceChanged" />
      </b-col>
    </b-row>
    <b-row v-if="componentMode === 'edit'">
      <b-col cols="8">
        <h4 class="mt-3">Изображения</h4>
        <Gallery :list="images" @delete="onImageDelete" @input="onFileSelected" />
      </b-col>
      <b-col cols="4">
        <h4 class="mt-3">Аудио</h4>
        <DropBox v-if="!model.audio_file" accepted-only accept="audio/mpeg" @input="onAudioInput" />
        <AudioPlayer v-else :file="model.audio_file" @delete="onAudioDelete" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue';

import AudioPlayer from '@/components/AudioPlayer.vue';
import DropBox from '@/components/DropBox.vue';
import Gallery from '@/components/galery/Gallery.vue';
import YandexMap from '@/components/YandexMap.vue';

import { Audio, Images, Place, PlaceTypes } from '@/request';

export default Vue.extend({
  name: 'NewPlace',
  components: { YandexMap, Gallery, DropBox, AudioPlayer },
  computed: {
    componentMode() {
      return this.$route?.meta?.mode || 'create';
    },
    pageTitle() {
      return this.componentMode === 'edit' ? 'Редактировать место' : 'Создать место';
    },
  },
  data() {
    return {
      model: {
        title: '',
        address: '',
        lat: 52.970756,
        lon: 36.064358,
        published: false,
        description: '',
        place_type_id: undefined,
        audio_file: undefined,
        show_on_homepage: undefined,
      },
      images: [],
      coords: [52.970756, 36.064358],
      placeTypes: [],
    };
  },
  async beforeMount() {
    this.placeTypes = await PlaceTypes.getPlaceTypes({
      per_page: 99,
      filters: { type: 'travel' },
    }).then(({ data }) => data);

    if (this.componentMode === 'edit') {
      this.getPlace();
      this.getPlaceMedia();
    }
  },
  methods: {
    async getPlace() {
      try {
        Object.assign(this.model, await Place.getPlace(this.$route.params.id));
        console.log('this.model', this.model);
        this.coords = [this.model.lat, this.model.lon];
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    async getPlaceMedia() {
      try {
        await this.getPlaceImages();
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    async getPlaceImages() {
      this.images = await Images.getPlaceImages(this.$route.params.id);
    },
    async createPlace() {
      try {
        const newPlace = await Place.createPlace(this.model);
        this.$bvToast.toast('Новое место создано', { variant: 'success' });
        await this.$router.push({ name: 'EditPlace', params: { id: newPlace.id } });
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    async updatePlace() {
      try {
        await Place.updatePlace(this.$route.params.id, this.model);
        this.$bvToast.toast('Место обновлено', { variant: 'success' });
        await this.$router.push({ name: 'Places' });
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    onSubmit() {
      switch (this.componentMode) {
        case 'create':
          this.createPlace();
          break;
        case 'edit': {
          this.updatePlace();
          break;
        }
        default:
          return;
      }
    },
    cancel() {
      this.$router.push({ name: 'Places' });
    },
    onPlaceChanged(event) {
      this.model.address = event.best?.name;
    },
    async onFileSelected(files) {
      if (!files.length) {
        return;
      }

      const formData = new FormData();
      for (const file of files) {
        formData.append('images[]', file, file.name);
      }

      try {
        await Images.uploadPlaceImages(this.$route.params.id, formData);
        await this.getPlaceImages();
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    async onAudioInput(files) {
      if (!files.length) {
        return;
      }

      const formData = new FormData();
      formData.append('file', files[0], files[0].name);

      try {
        await Audio.uploadPlaceAudio(this.$route.params.id, formData);
        await this.getPlace();
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    async onAudioDelete() {
      try {
        await Audio.deletePlaceAudio(this.$route.params.id);
        await this.getPlace();
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    async onImageDelete(image) {
      try {
        await Images.deleteImage(image.id);
        await this.getPlaceImages();
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
  },
  watch: {
    coords(newVal) {
      if (Array.isArray(newVal)) {
        [this.model.lat, this.model.lon] = newVal;
      }
    },
  },
});
</script>

<style lang="scss" scoped></style>
